import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

const host =  process.env.NODE_ENV === "development" ? "http://192.168.80.106:3238" : "";

function Entry() {

  const history = useHistory();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault()

    const kodKlienta = document.getElementById('kname').value;

    if(kodKlienta) {
      setError(false);
      setLoading(true);
      fetch(host + "/api/greet", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ kk: kodKlienta }) })
      .then(res => {
        if(res.ok) {
          return res.json()
        }
        else { throw res }
      })
      .then(jData => {
        localStorage.setItem('ch_ofe_kk', JSON.stringify(jData))
          
        const urlParams = new URLSearchParams(document.location.search);
        const rpParam = urlParams.get('returnPath');

        history.push(rpParam || '/')
      })
      .catch(err => { setError(true); setLoading(false) })
    }
    else {
      setError(true);
    }
  }

  return (
    <div className='container-center'>
      <img src='logo.png' alt='' width={300} style={{ position: "absolute", top: "20%"}} />
      <div className='card' style={{ width: "20rem"}}>
        <div className='card-body'>
          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="mb-3">
              <label htmlFor="kname" className="form-label">Podaj swój login</label>
              <input type="text" maxLength={13} className="form-control" id="kname" aria-describedby="knameHelp" />
              {error && <div style={{ marginTop: '0.25rem', fontSize: '0.875rem', color: 'red' }}>Błąd logowania, spróbuj ponownie<br /><small>Jeśli problem się powtarza skontaktuj się ze swoim opiekunem</small></div>}
            </div>
            <div className="d-grid gap-2">
            <button type="submit" className="btn btn-primary" disabled={loading}>
              {loading && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
              Wejdź
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Entry
import React from 'react'

function NotFound() {
  return (
    <div>
      <header>
        <p>
          {'¯\\_( ͡° ͜ʖ ͡°)_/¯'}
        </p>
        <p>
          Nic tu nie ma.
        </p>
      </header>
    </div>
  );
}

export default NotFound
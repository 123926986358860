import React from 'react';
import '../css/LoadingScreen.css';

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <div className="loading-logo">
        <img src="logo192.png" alt="Logo" width="150" />
      </div>
    </div>
  );
}

export default LoadingScreen;
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import '../css/chomik.css'
import LoadingScreen from '../components/LoadingScreen';

const host = process.env.NODE_ENV === "development" ? "http://192.168.80.106:3238" : "";

const BrakOfert = () => {
    return (
        <h3>Aktualnie nie udostępniamy żadnych ofert</h3>
    )
}


function Home() {

    const [oferty, setOferty] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null)

    const history = useHistory();

    useEffect(() => {
        const fetchOferty = () => {
            const params = new URLSearchParams(document.location.search);
            const oi = params.get('oi')

            fetch(host + "/api/oferty" + (oi ? "?oi=" + oi : ""))
                .then(res => {
                    if (res.ok) { return res.json() }
                    else { throw res }
                })
                .then(json => { setOferty(json); setLoading(false) })
                .catch(err => { history.push(`/wejscie?returnPath=${document.location.pathname}${document.location.search}`) })
        }

        fetchOferty()

        setUser(JSON.parse(localStorage.getItem('ch_ofe_kk')))
    }, [])

    if (loading) return <LoadingScreen />

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-1'></div>
                <div className='col-10'>
                    <div style={{ marginBottom: "2rem" }}>
                        <h1 style={{ margin: "1rem 0", fontWeight: "bold" }}>AKTUALNE OFERTY</h1>
                        {oferty.length > 0 ?
                            <div className="card">
                                {(oferty[0].Grafika && oferty[0].Grafika.data) && <img src={oferty[0].Grafika.data.attributes.url} style={{ padding: "1rem", paddingBottom: 0 }} alt="..." />}
                                <div className="card-body">
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <h4 style={{ fontWeight: "bold" }}>{oferty[0].Nazwa}</h4>
                                        <p className={`card-text ${new Date(oferty[0].DataKoniec) - new Date() < 3 * 24 * 60 * 60 * 1000 ? "text-danger" : "text-muted"}`}>{"aktualna do " + oferty[0].DataKoniec.split("T")[0]}</p>
                                    </div>
                                    <div className='oferta-przyciski'>
                                        {oferty[0].LinkExcel && <a href={oferty[0].LinkExcel + "?oi=" + oferty[0].id + "&kid=0" + "&kk=" + user.kodKontrahenta} className="btn btn-warning btn-lg" role="button" aria-pressed="true">Pobierz Excel</a>}
                                        {oferty[0].LinkPDF && <a href={oferty[0].LinkPDF + "?oi=" + oferty[0].id + "&kid=0" + "&kk=" + user.kodKontrahenta} className="btn btn-warning btn-lg" role="button" aria-pressed="true">Pobierz PDF</a>}
                                    </div>
                                    <small className="card-text" style={{ lineHeight: "1" }}>{oferty[0].Opis}</small>
                                </div>
                                <div className="card border-light pozostale-oferty" style={{ marginTop: "1rem" }}>
                                    <h4 className="card-header">pozostałe oferty</h4>
                                    <div className="card-body">
                                    {oferty.length > 1 ?
                                        oferty.slice(1).map((o, i) => (
                                            <div key={i} style={{ borderBottom: "1px solid lightgray" }}>
                                                <div key={i} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "0.5rem" }}>
                                                    <span style={{ fontSize: "14", fontWeight: "bold" }}>{o.Nazwa}</span>
                                                    <span className={`${new Date(o.DataKoniec) - new Date() < 3 * 24 * 60 * 60 * 1000 ? "text-danger" : "text-muted"}`}>{`${new Date(o.DataKoniec) - new Date() < 3 * 24 * 60 * 60 * 1000 ? "oferta wygasa " : "aktualna do "} ${o.DataKoniec.split("T")[0]}`}</span>
                                                </div>
                                                <div className='oferta-przyciski'>
                                                    {o.LinkExcel && <a href={o.LinkExcel + "?oi=" + o.id + "&kid=0" + "&kk=" + user.kodKontrahenta} className="btn btn-warning btn-sm" role="button" aria-pressed="true">Pobierz Excel</a>}
                                                    {o.LinkPDF && <a href={o.LinkPDF + "?oi=" + o.id + "&kid=0" + "&kk=" + user.kodKontrahenta} className="btn btn-warning btn-sm" role="button" aria-pressed="true">Pobierz PDF</a>}
                                                    {(!o.LinkPDF && !o.LinkPDF) && <a href={"?oi=" + o.id + "&kk=" + user.kodKontrahenta} className="btn btn-warning btn-sm" role="button" aria-pressed="true">Strona oferty</a>}
                                                </div>
                                                <small>{o.Opis}</small>
                                            </div>
                                        ))
                                        :
                                        <p>Aktualnie nie prezentujemy innych ofert</p>
                                    }
                                    </div>
                                </div>
                            </div>
                            :
                            <BrakOfert />
                        }

                        {user.opiekun &&
                            <div style={{ marginTop: '1rem' }}>
                                <div style={{ margin: "0 auto", padding: "1rem", border: '1px solid gray', borderRadius: '5px', boxShadow: '2px 2px 1px gray', maxWidth: '500px' }}>
                                    <div>
                                        <h5>Twój opiekun</h5>
                                        <div style={{ display: 'flex' }}>
                                        <img style={{ marginRight: '1rem' }} src={`${host}/api/handlowcy/img/${user.opiekun.dział.toLowerCase()}${user.opiekun.id}${user.opiekun.telefon.slice(-3)}${user.opiekun.nazwa.split(' ')[0].toLowerCase()}.jpg`} alt='' />
                                        <div>
                                            <div><strong>{user.opiekun.nazwa}</strong></div>
                                            <div>telefon: <a href={'tel:' + user.opiekun.telefon.replaceAll('-', "")}>{user.opiekun.telefon}</a></div>
                                            <div>e-mail: <a href={'mailto:' + user.opiekun.email}>{user.opiekun.email}</a></div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className='col-1'></div>
            </div>
        </div>

    );
}

export default Home